import * as Navigation from "../NavigationTS";
import * as TimeZone from "./TimeZoneTS";
import * as Analytics from "../AnalyticsTS";
import * as Optimizely from "../OptimizelyTS";
import { App, Module, SendPort, SubscribePort, Ports } from "../ElmApp";

// Polyfills Element.closest for browsers that don't support it. (Most notably
// Edge version 18 and below, which used the Triton engine.)

// TODO:  fix element-closest requirement
// require("element-closest")(window); // eslint-disable-line

/*
Nri/ProgramTS.ts

This file wraps the creation of an Elm program in order to include
setup that is relevant for all NoRedInk programs. The responsibilities
of this module are:

- Set up an Env that is passed to the update function and various
  initialization steps. The Env includes variables set in _nri_env.html.erb:
  - context
  - Bugsnag config
- Create the Elm program. The current method for setting up Elm programs
  is to always embed into a host element. In the future we can modify it
  to allow for fullscreen programs directly, or alternatively you can
  embed programs into document.body
- Pass the Elm app to any shared modules that use ports:
  - Navigation.ts, which emulates the 0.18 Navigation API in order to
    give us some more time to upgrade to Browser.application

*/

/*
  This file is versioned alongside to facilitate the use of Program.environ()
  in Contento. Contento (that is, the code that lives in <root>/contento and
  decodes/uses the environment) is deployed out of sync with the monolith which
  supplies it via some code injected into the page head (client request hits
  monolith, monolith requests page from contento, injects the contents of
  _extra_head.html.erb, serves the response).

  When versioning:
  - add the new version to EnvionVersions and environ
  - bump the default version in Environ's (Version generic parameter)
  - AFTER Contento has been deployed, remove the old version
    (and associated deprecated data from _nri_env.html.erb)
*/

export type Environ<Version extends EnvironVersion = 1> =
  EnvironVersions[Version];

export type EnvironVersion = keyof EnvironVersions;

type EnvironVersions = {
  1: {
    report: {
      token: string;
      codeVersion: string;
      releaseStage: string;
      notifyReleaseStages: string[];
      user: NriUser | undefined;
    };
    context: string;
    lms: Lms;
    timeZoneSettings: TimeZone.Settings;
    currentUser: NriUser | null;
    optimizelyExperimentData: ExperimentsData;
    coTeachers: CoTeacher[];
    csrfToken: string;
    displayATNotificationCenter: boolean | undefined;
    readAloud: ReadAloud;
    prefersReducedMotion: boolean;
    notifyFailedToMoveFocus: boolean | undefined;
  };
};

// The data for the env is set up in _nri_env.html.erb
export function environ(): Environ;
export function environ<T extends EnvironVersion>(version: T): Environ<T>;
export function environ<T extends EnvironVersion>(version?: T): Environ<T> {
  const versions = {
    1: {
      report: {
        token: window.NRI_ENV.elmBugsnagApiKey,
        codeVersion: window.NRI_ENV.bugsnagClientCodeVersion,
        releaseStage: window.NRI_ENV.bugsnagClientReleaseStage,
        notifyReleaseStages: window.NRI_ENV.bugsnagClientNotifyReleaseStages,
        user: window.NRI_ENV.user,
      },

      context: window.NRI_ENV.context,
      lms: window.NRI_ENV.lms,
      timeZoneSettings: TimeZone.settings(),
      currentUser: window.NRI_ENV.user || null,
      optimizelyExperimentData: window.NRI_ENV.optimizelyExperimentData || {},
      coTeachers: window.NRI_ENV.coTeachers || [],
      csrfToken:
        document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")
          ?.content || "",
      displayATNotificationCenter: window.NRI_ENV.displayATNotificationCenter,
      readAloud: {
        enabled: window.NRI_ENV.readAloudEnabled == true,
        featureFlagReadAloudV2: window.NRI_ENV.featureFlagReadAloudV2 == true,
        featureFlagReadAloudService:
          window.NRI_ENV.featureFlagReadAloudService == true,
        token: window.NRI_ENV.readAloudToken,
        url: window.NRI_ENV.readAloudServiceUrl,
      },
      prefersReducedMotion: window.matchMedia(
        "(prefers-reduced-motion: reduce)",
      ).matches,
      notifyFailedToMoveFocus: Math.random() < 0.01, // 1% of page loads will report failed to move focus errors
    },
  };
  const latestVersion = Math.max(...Object.keys(versions).map(Number));
  return versions[version || (latestVersion as T)];
}

type NotifyPayload = {
  scope: string;
  message: string;
  metadata: unknown;
};

interface NotifyPort {
  notifyPort: SubscribePort<NotifyPayload>;
}

type AppPorts = NotifyPort &
  Navigation.Ports &
  ReducedMotionPreferenceSetPort &
  AttemptFocusWithoutScrollPort &
  AttemptScrollIntoViewPort &
  DomElementNotFoundPort &
  SetPageMetaDataPort &
  TabCmdPorts &
  Optimizely.Ports &
  BroadCastCurrentUrlToWindowPort;

type ProgramFlags<Flags> = {
  flags: Flags;
  env: Environ;
  location: Navigation.NavigationLocation;
};

export const program = function <
  Flags,
  ProgramPorts extends Ports & Partial<AppPorts>,
>(
  ElmModule: Module<ProgramFlags<Flags>, ProgramPorts>,
  host: HTMLElement,
  data: Flags,
  filename: string,
): App<ProgramPorts> {
  const env = environ();
  if (env.context === "development") {
    console.time("elm started");
    console.info("file:", filename);
    console.log("to:", host, "with initial flags:");
    console.log(data);
    console.log(env);
  }
  const flags = {
    flags: data,
    env,
    location: Navigation.getLocation(window.location),
  };
  const app = ElmModule.init({ node: host, flags });
  Navigation.setup(app);
  if (env.context === "development") {
    console.timeEnd("elm started");
  }

  if (app.ports.notifyPort) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    app.ports.notifyPort.subscribe((msg: NotifyPayload) => {
      window.elmBugsnagClient.notify(
        // we don't send raw string because otherwise bugsnag will group all errors with the same name "Error"
        { name: msg.message, message: "—" },
        { context: msg.scope, severity: "error", metaData: msg.metadata },
      );
      if (env.context === "development" || env.context === "staging") {
        // We show the message in dev and staging so that we can see it in the console
        console.log(msg);
      }
    });
  } else {
    console.warn(
      "notifyPort is not available. Can't send bugsnag errors to Elm!",
    );
  }

  nriEnvStart(app);

  subscribeDomEffects(app);

  subscribeTabCmds(app);

  setPageMetaData(app);

  subscribeBroadcastUrl(app);

  Optimizely.setupActivationPort(app, env.optimizelyExperimentData);

  return app;
};

interface ReducedMotionPreferenceSetPort {
  reducedMotionPreferenceSet: SendPort<boolean>;
}

function subscribeReducedMotionPreferenceSet(
  app: App<Partial<ReducedMotionPreferenceSetPort>>,
): void {
  // using a const to hold this value for callbacks
  const port = app.ports.reducedMotionPreferenceSet;

  if (!port) {
    console.warn(
      "reducedMotionPreferenceSet was not defined; can't send motion preference to Elm!",
    );
    return;
  }

  const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

  port.send(mediaQuery.matches);
  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener("change", () => port.send(mediaQuery.matches));
  } else if (mediaQuery.addListener) {
    mediaQuery.addListener(() => port.send(mediaQuery.matches));
  }
}

interface AnnouncementPort {
  announceAssertivelyFromTS: SendPort<string>;
}

function subscribeAnnouncements(app: App<Partial<AnnouncementPort>>): void {
  const port = app.ports.announceAssertivelyFromTS;

  if (!port) {
    console.warn(
      "announceAssertivelyFromTS was not defined; can't send assertive announcement for notification center to Elm!",
    );
    return;
  }

  document.addEventListener("announceAssertively", (announcement) =>
    port.send((<CustomEvent>announcement).detail),
  );
}

export type NriEnvPorts = ReducedMotionPreferenceSetPort &
  AnnouncementPort &
  Optimizely.Ports;

export function nriEnvStart<Ports extends Partial<NriEnvPorts>>(
  app: App<Ports>,
): void {
  subscribeReducedMotionPreferenceSet(app);

  subscribeAnnouncements(app);

  const env = environ();

  Optimizely.setupActivationPort(app, env.optimizelyExperimentData);
  Optimizely.setupVariationChangePorts(app);
}

interface AttemptFocusWithoutScrollPort {
  attemptFocusWithoutScroll: SubscribePort<string>;
}

interface AttemptScrollIntoViewPort {
  attemptScrollIntoView: SubscribePort<{
    elementId: string;
    options: ScrollIntoViewOptions;
  }>;
}

interface BroadCastCurrentUrlToWindowPort {
  broadcastCurrentUrlToWindow: SubscribePort<null>;
}

function subscribeDomEffects(
  app: App<
    Partial<
      AttemptFocusWithoutScrollPort &
        AttemptScrollIntoViewPort &
        DomElementNotFoundPort
    >
  >,
): void {
  if (!app.ports.attemptFocusWithoutScroll) {
    console.warn(
      "the attemptFocusWithoutScroll port wasn't defined. That won't work from Elm!",
    );
    return;
  }

  app.ports.attemptFocusWithoutScroll.subscribe((elementId) =>
    window.requestAnimationFrame(() => focusWithoutScroll(app, elementId)),
  );

  if (!app.ports.attemptScrollIntoView) {
    console.warn(
      "the attemptScrollIntoView port wasn't defined. That won't work from Elm!",
    );
    return;
  }

  app.ports.attemptScrollIntoView.subscribe(
    (args: { elementId: string; options: ScrollIntoViewOptions }) =>
      window.requestAnimationFrame(() =>
        scrollIntoView(app, args.elementId, args.options),
      ),
  );
}

function subscribeBroadcastUrl(
  app: App<Partial<BroadCastCurrentUrlToWindowPort>>,
): void {
  if (!app.ports.broadcastCurrentUrlToWindow) {
    console.warn(
      "the broadcastCurrentUrlToWindow port wasn't defined. That won't work from Elm!",
    );
    return;
  }

  app.ports.broadcastCurrentUrlToWindow.subscribe(() => {
    document.dispatchEvent(
      new CustomEvent("PathIsNow", {
        detail: { path: window.location.pathname },
      }),
    );
  });
}
interface DomElementNotFoundPort {
  domElementNotFound: SendPort<{
    domEventType: "focusWithoutScroll" | "scrollIntoView";
    elementId: string;
  }>;
}

function focusWithoutScroll(
  app: App<Partial<DomElementNotFoundPort>>,
  elementId: string,
): void {
  const element = document.getElementById(elementId);
  if (element) {
    element.focus({ preventScroll: true });
  } else {
    // Report focus without scroll failed to Elm app:
    app.ports.domElementNotFound?.send({
      domEventType: "focusWithoutScroll",
      elementId: elementId,
    });
  }
}

function scrollIntoView(
  app: App<Partial<DomElementNotFoundPort>>,
  elementId: string,
  options: ScrollIntoViewOptions,
): void {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView(options);
  } else {
    // Report scroll into view failed to Elm app:
    app.ports.domElementNotFound?.send({
      domEventType: "scrollIntoView",
      elementId: elementId,
    });
  }
}

interface SetPageMetaDataPort {
  setPageMetaData: SubscribePort<{ title?: string; description?: string }>;
}

function setPageMetaData(app: App<Partial<SetPageMetaDataPort>>): void {
  if (!app.ports.setPageMetaData) {
    console.warn(
      "the setPageMetaData port was not defined; I can't set metadata from Elm!",
    );
    return;
  }

  app.ports.setPageMetaData.subscribe((values) => {
    if (values["title"]) {
      document.title = values["title"];
    }

    if (values["description"]) {
      const meta = document.querySelector(
        "meta[name=description]",
      ) as HTMLMetaElement;
      if (meta) {
        meta.content = values["description"];
      }
    }
  });
}

interface TabCmdPorts {
  closeTabCmd: SubscribePort<null>;
  openUrlInNewTabCmd: SubscribePort<string>;
}

function subscribeTabCmds(app: App<Partial<TabCmdPorts>>): void {
  if (!app.ports.closeTabCmd) {
    console.warn("the closeTabCmd port was not defined; Elm can't close tabs!");
  } else {
    app.ports.closeTabCmd.subscribe(() => {
      close();
    });
  }

  if (!app.ports.openUrlInNewTabCmd) {
    console.warn(
      "the openUrlInNewTabCmd port was not defined; Elm can't open URLs in new tabs!",
    );
  } else {
    app.ports.openUrlInNewTabCmd.subscribe((url) => {
      window.open(url);
    });
  }
}

// Loads JSON flags from elementId
//
//    ElmProgram.flags 'elm-flags'
//
// will load view flags from:
//    #elm-flags{data-flags: @page_data.to_json}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const flags = function (elementId: string, field?: string): any {
  if (field == null) {
    field = "flags";
  }
  return JSON.parse(
    document.getElementById(elementId)?.dataset[field] || "null",
  );
};

// Mounts Elm app module into element hostId with specified flags
export const mountWithFlags = function <
  Flags,
  ProgramPorts extends Ports & Partial<AppPorts>,
>(
  module: Module<ProgramFlags<Flags>, ProgramPorts>,
  hostId: string,
  flags: Flags,
  filename: string,
): App<ProgramPorts> {
  const el = document.getElementById(hostId);

  if (!el) {
    throw `I tried to mount an Elm app at "#${hostId}" but that ID doesn't exist!`;
  }

  return program(module, el, flags, filename);
};

// Mounts module at elm-host using flags from elm-flags
// optionally prefix "foo-elm" mounts at foo-elm-host, foo-elm-flags
export const mount = function <
  AnalyticsCallback,
  AnalyticsProperty,
  PortsToMount extends Ports &
    Partial<AppPorts & Analytics.Ports<AnalyticsCallback, AnalyticsProperty>>,
>(
  module: Module<unknown, PortsToMount>,
  filename: string,
  prefix?: string,
  jsFlags?: Record<string, unknown>,
): App<PortsToMount> {
  if (prefix == null) {
    prefix = "elm";
  }
  const hostId = prefix + "-host";
  const flagsId = prefix + "-flags";
  const htmlFlags = flags(flagsId);
  const mergedFlags = jsFlags ? { ...htmlFlags, ...jsFlags } : htmlFlags;
  const app = mountWithFlags(module, hostId, mergedFlags, filename);
  Analytics.start(app);
  return app;
};

export const mountMany = function <
  AnalyticsCallback,
  AnalyticsProperty,
  PortsToMount extends Ports &
    Partial<AppPorts & Analytics.Ports<AnalyticsCallback, AnalyticsProperty>>,
>(
  module: Module<unknown, PortsToMount>,
  filename: string,
  prefix?: string,
  jsFlags?: Record<string, unknown>,
): {
  app: App<PortsToMount>;
}[] {
  if (prefix == null) {
    prefix = "elm";
  }
  const hosts = Array.from(document.getElementsByClassName(prefix + "-host"));

  const apps = [] as {
    app: App<PortsToMount>;
  }[];

  hosts.forEach((host) => {
    const flagsElem = host.previousElementSibling as HTMLElement;
    if (flagsElem) {
      const htmlFlags = JSON.parse(flagsElem.dataset["flags"] || "null");
      const mergedFlags = jsFlags ? { ...htmlFlags, ...jsFlags } : htmlFlags;
      const app = program(module, host as HTMLElement, mergedFlags, filename);

      Analytics.start(app);
      apps.push({ app });
    }
  });

  return apps;
};

declare global {
  interface Window {
    nriFlags: () => Record<string, unknown>;
  }
}

// mapping of id to json contents of all elm_mount data elements for debugging
window.nriFlags = function (): Record<string, unknown> {
  const flagElements = Array.from(document.getElementsByClassName("elm-flags"));
  return Object.fromEntries(
    flagElements.map((el) => [
      el.id,
      JSON.parse((el as HTMLElement).dataset["flags"] || "null"),
    ]),
  );
};

/*!
 * domready (c) Dustin Diaz 2014 - License MIT
 *
 * this is vendored code, so we're not going to try to type it.
 */
export const domready = (function () {
  // @ts-expect-error vendored
  let fns = [], // eslint-disable-line
    // @ts-expect-error vendored
    listener,
    doc = typeof document === "object" && document, // eslint-disable-line
    // @ts-expect-error vendored
    hack = doc && doc.documentElement.doScroll, // eslint-disable-line
    domContentLoaded = "DOMContentLoaded", // eslint-disable-line
    loaded =
      doc && (hack ? /^loaded|^c/ : /^loaded|^i|^c/).test(doc.readyState);

  if (!loaded && doc)
    doc.addEventListener(
      domContentLoaded,
      (listener = function () {
        // @ts-expect-error vendored
        doc.removeEventListener(domContentLoaded, listener); // eslint-disable-line
        // @ts-expect-error vendored
        loaded = 1;
        // @ts-expect-error vendored
        while ((listener = fns.shift())) listener(); // eslint-disable-line
      }),
    );

  // @ts-expect-error vendored
  return function (fn) {
    loaded ? setTimeout(fn, 0) : fns.push(fn); // eslint-disable-line
  };
})();

// Sometimes we want to close tabs opened by the app. Unfortunately,
// in tests this closes the browser instance and all subsequent tests
// start failing. So by using this helper we keep our tests happy.
// We also take a fallbackUrl in case the page wasn't opened by the app:
// in that case, window.close() would fail so we need to send the user
// somewhere else.
export const closeOpenedTab = function (fallbackUrl: string): void {
  if (window.NRI_ENV.context !== "test") {
    window.close();
  }
  location.href = fallbackUrl;
};

export const navigation = Navigation;
