import algoliasearch from "algoliasearch/dist/algoliasearch.js";
import type { Index } from "algoliasearch/dist/algoliasearch.js";

class Algolia {
  schoolIndex: Index;

  constructor() {
    // search API key that can be exposed
    const algolia = algoliasearch(
      "UXMFUFJMOI",
      "5ec5fa0f219e785bf3ed78beddcfe0f4",
    );

    const meta: HTMLMetaElement | null = document.head.querySelector(
      'meta[name="rails-env"]',
    );

    let env = meta?.content;
    if (env == null) {
      env = "development";
    }
    if (env === "demo") {
      env = "staging";
    }

    this.schoolIndex = algolia.initIndex(`schools ${env}`);
  }
}

export default Algolia;
