import * as Analytics from "../../../lib/nri-elm-shared--src/AnalyticsTS";
import Algolia from "../../Algolia/Algolia";
import * as Program from "../../../lib/nri-elm-shared--src/Nri/ProgramTS";
import type {
  App,
  SendPort,
  SubscribePort,
} from "../../../lib/nri-elm-shared--src/ElmApp";

import * as LeanData from "./LeanData";
import "../../WebComponents/Calendly.ts";

// Every page served from contentful will be injected with some javascript code
// defined in monolith/app/views/contento/_extra_head.html.haml
// That will make setupNriElmApp available to be called when the elm-pages app
// is loaded from contento/ui/public/index.js

declare global {
  interface Window {
    setupNriElmApp<Callback, Property>(
      app: App<Ports<Callback, Property>>,
    ): void;
    programEnviron<T extends Program.EnvironVersion>(
      version: T,
    ): Program.Environ<T>;
  }
}

export type ContentoPorts = {
  sendSchoolSearchQuery: SubscribePort<{ query: string; state: string }>;
  receiveSchoolSearchResults: SendPort<unknown>;
};

export type Ports<Callback, Property> = ContentoPorts &
  Partial<Analytics.Ports<Callback, Property>> &
  Partial<LeanData.Ports> &
  Partial<Program.NriEnvPorts>;

window.setupNriElmApp = <Callback, Property>(
  app: App<Ports<Callback, Property>>,
) => {
  Analytics.start(app);
  LeanData.start(app);
  Program.nriEnvStart(app);

  app.ports.sendSchoolSearchQuery.subscribe(function ({ query, state }) {
    const { schoolIndex } = new Algolia();

    const opts = state ? { facetFilters: `state:${state}` } : {};
    schoolIndex.search(query, opts, function (err, content) {
      if (!err) {
        app.ports.receiveSchoolSearchResults.send(content.hits);
      }
    });
  });
};

window.programEnviron = <T extends Program.EnvironVersion>(
  version: T,
): Program.Environ<T> => Program.environ(version);
