/*
Nri/TimeZoneTS.ts
*/

const timeZoneNameFromPreferences = (): string => window.NRI_ENV.timeZoneName;

const timeZoneNameFromBrowser = function (): string | null {
  if (typeof Intl === "object" && typeof Intl.DateTimeFormat === "function") {
    const format = Intl.DateTimeFormat();
    if (format != null && typeof format.resolvedOptions === "function") {
      const options = format.resolvedOptions();
      if (options != null && typeof options.timeZone === "string") {
        return options.timeZone;
      }
    }
  }

  return null;
};

export interface Settings {
  fromPreferences: string;
  fromBrowser: string | null;
  reportStats: boolean;
}

export const settings = (): Settings => ({
  fromPreferences: timeZoneNameFromPreferences(),
  fromBrowser: timeZoneNameFromBrowser(),
  reportStats: window.NRI_ENV.timeZoneStats,
});
