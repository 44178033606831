import type { App, SendPort, SubscribePort } from "./ElmApp";

type Ports = {
  activateOptimizely: SubscribePort<number>;
  addUserVariation: SendPort<[number, number]>;
};

type AppWithOptimizely = App<Partial<Ports>>;

const setupVariationChangePorts = (app: AppWithOptimizely): void => {
  if (window.optimizely) {
    subscribeToVariationChanges(app);
  }

  exposeEditorVariationSetter(app);
};

const setupActivationPort = (
  app: AppWithOptimizely,
  experimentsData: ExperimentsData,
) => {
  /*
   * This port is used for inserting the Optimizely snippet in our app.
   * This was added as a way to prevent overusage of MAUs.
   * It also checks if there's the experiment that is used for turning Optly
   * on is indeed active, and refuses to activate if it's not.
   * */
  app.ports.activateOptimizely?.subscribe((experimentId: number) => {
    if (experimentsData.activeExperiments?.includes(experimentId)) {
      const optimizelyPreloadElement = document.getElementById(
        "optimizely-preload-script",
      );
      const optimizelyScriptId = "optimizely-script";
      if (
        optimizelyPreloadElement &&
        !document.getElementById(optimizelyScriptId)
      ) {
        const optlyScript = document.createElement("script");
        optlyScript.src = "//cdn.optimizely.com/js/20530820202.js";
        optlyScript.id = optimizelyScriptId;

        document.body.append(optlyScript);
        console.info("Optimizely was activated.");
      }
    } else {
      console.info(
        `Optimizely was not activated, because the experiment with id ${experimentId} is not active.`,
      );
    }
  });
};

const subscribeToVariationChanges = (app: AppWithOptimizely) => {
  /*
   * Some Optimizely experiments need to trigger after page load.
   * For this to work, we have a port that updates the activeVariations
   * environment. This listens for the Optimizely `campaignDecided` event,
   * and the insert the proper `experimentId` and `variationId` in our variation dictionary.
   */
  window.optimizely?.push({
    type: "addListener",
    filter: {
      type: "lifecycle",
      name: "campaignDecided",
    },
    handler: ({ data }) => {
      try {
        const { experimentId, variationId } = data.decision;
        // This can be `null`, since campaignDecided not always buckets the user.
        if (experimentId && variationId) {
          app.ports.addUserVariation?.send([
            Number(experimentId),
            Number(variationId),
          ]);
          console.log(
            `Setting experiment ID ${experimentId} variation as ${variationId}`,
          );
        }
      } catch (e) {
        console.error("Failed to push new Optimizely variations");
        console.error(e);
      }
    },
  });
};

const exposeEditorVariationSetter = (app: AppWithOptimizely) => {
  /*
   * Optimizely's editor does not expose the window.optimizely object
   * To be able to edit experiments done in Elm through the optimizely editor, we need to have
   * a way of telling Elm the intended variation. This can be done through adding a custom
   * Javascript in the variation, which should look like:

   * const utils = window["optimizely"].get('utils');
   * utils.waitUntil(() => !!window.setEditorVariation)
   *      .then(window.setEditorVariation(<experiment-id>, <variation-id>))
   */

  window.setEditorVariation = (experimentId, variationId) => {
    // Once the editor is loaded, window contains an `optimizely_editor_data_endpoint` property
    // Object.hasOwnProperty doesn't work because this property is inherited from Optimizely.
    const isInEditor = "optimizely_editor_data_endpoint" in window;

    if (isInEditor) {
      app.ports.addUserVariation?.send([
        Number(experimentId),
        Number(variationId),
      ]);
    }
  };
};

export { setupActivationPort, setupVariationChangePorts };
export type { Ports };
