type Callback<T> = (value: T) => void;

class CalendlyEmbed extends HTMLElement {
  private _scriptInjected = false;

  async connectedCallback() {
    const Calendly = await this.getCalendly();

    const { calendarUrl: url, name, email } = this.dataset;

    if (typeof url !== "string") {
      throw new Error("Missing required `data-calendar-url` attribute");
    }
    if (typeof name !== "string") {
      throw new Error("Missing required `data-name` attribute");
    }
    if (typeof email !== "string") {
      throw new Error("Missing required `data-email` attribute");
    }

    Calendly.initInlineWidget({
      url,
      parentElement: this,
      prefill: { name, email },
    });

    window.addEventListener("message", (e: MessageEvent) => {
      if (!isCalendlyEvent(e)) return;
      const { event } = e.data;
      this.dispatchEvent(new CustomEvent(event));
    });
  }

  async getCalendly(): Promise<Calendly> {
    if (!this._scriptInjected) {
      const snippet = document.createElement("script");
      snippet.setAttribute(
        "src",
        "https://assets.calendly.com/assets/external/widget.js",
      );
      document.head.appendChild(snippet);
      this._scriptInjected = true;
    }
    const resolveIfLoaded = (resolve: Callback<Calendly>) => {
      if (window.Calendly) {
        resolve(window.Calendly);
      } else {
        setTimeout(resolveIfLoaded, 100, resolve);
      }
    };
    return new Promise((resolve) => resolveIfLoaded(resolve));
  }
}

function isCalendlyEvent(e: MessageEvent): e is CalendlyPostMessageEvent {
  return !!(
    e.origin === "https://calendly.com" &&
    "event" in e.data &&
    "payload" in e.data
  );
}

window.customElements.define("calendly-embed", CalendlyEmbed);
