/**
 * This based off of a snippet provided by LeanData, made to work with Elm (and to a lesser degree TypeScript).
 * The spirit is the same, but we know the data that is being passed back and forth, when that data is being
 * passed back and forth, and are validating it ourselves, so we don't need the magic of the original snippet.
 */

import {
  App,
  SendPort,
  SubscribePort,
} from "../../../lib/nri-elm-shared--src/ElmApp";

declare global {
  interface Window {
    LeanDataCalendaringObjName: "LDCalendaring";
    LDCalendaring: LDCalendaring;
  }
}

interface LDCalendaring {
  (
    formSalesforceId: string,
    formName: string,
    enableLocalStorage: boolean,
    options: LDCalendaringOptions,
  ): void;
  variables?: Parameters<LDCalendaring>;
}

type LDCalendaringOptions = {
  popupModalOn: boolean;
};

type PardotFormData = {
  Email: string;
  "First Name": string;
  "Last Name": string;
  Phone: string;
  "Job Title": string;
  Grades: string[];
  "School or District": string;
  "School ID": number;
  "District ID": string;
  State: string;
  "NRI User ID": number;
  "LD BookIt Calendar Link": string;
  LD_BookIt_Log_ID__c?: string;
};

export type Ports = {
  leanDataEnable: SubscribePort<unknown>;
  leanDataRoutePremiumApplication: SubscribePort<PardotFormData>;
  leanDataPremiumApplicationRouted: SendPort<PardotFormData>;
  leanDataPremiumApplicationRoutingError: SendPort<string>;
};

const PARDOT_PREMIUM_APPLICATION_SALESFORCE_ID = "00D41000002GZayEAG";
const PARDOT_PREMIUM_APPLICATION_FORM_NAME = "Premium Application Form";
const LD_CALENDARING_OBJ_NAME = "LDCalendaring";
const LD_CALENDAR_URL_KEY = `${LD_CALENDARING_OBJ_NAME}_pardot_calendarLink`;
const LD_SCRIPT_SRC = "https://app.leandata.com/js-snippet/ld_calendaring.js";
const LD_ENABLE_LOCAL_STORAGE = true;
const LD_OPTIONS: LDCalendaringOptions = {
  popupModalOn: false,
};

let enableLdCalendaringError = false;

export function start(app: App<Partial<Ports>>) {
  const {
    leanDataEnable,
    leanDataRoutePremiumApplication,
    leanDataPremiumApplicationRouted,
    leanDataPremiumApplicationRoutingError,
  } = app.ports;
  if (
    !leanDataEnable ||
    !leanDataRoutePremiumApplication ||
    !leanDataPremiumApplicationRouted ||
    !leanDataPremiumApplicationRoutingError
  ) {
    console.warn(
      "LeanData ports not found, Premium Application will NOT work.",
    );
    return;
  }

  leanDataEnable.subscribe(enable);

  leanDataRoutePremiumApplication.subscribe((premiumApplicationData) => {
    routePremiumApplication(premiumApplicationData)
      .then((response) => {
        leanDataPremiumApplicationRouted.send(response);
      })
      .catch((err) => {
        console.error(err);
        const errMessage = err instanceof Error ? err.message : String(err);
        leanDataPremiumApplicationRoutingError.send(errMessage);
      });
  });
}

export function enable() {
  // Add and populate the stub that the ld_calendaring.js script expects to find on initialization
  window.LeanDataCalendaringObjName = LD_CALENDARING_OBJ_NAME;
  window.LDCalendaring = (...args: Parameters<LDCalendaring>) => {
    window.LDCalendaring.variables = args;
  };
  window.LDCalendaring(
    PARDOT_PREMIUM_APPLICATION_SALESFORCE_ID,
    PARDOT_PREMIUM_APPLICATION_FORM_NAME,
    LD_ENABLE_LOCAL_STORAGE,
    LD_OPTIONS,
  );

  const script = document.createElement("script");
  script.async = true;
  script.src = LD_SCRIPT_SRC;
  document.head.append(script);

  script.addEventListener("error", (err) => {
    enableLdCalendaringError = true;
    console.error(
      `Error loading ${LD_SCRIPT_SRC}, premium application will not be properly routed in Salesforce.`,
      err,
    );
  });
}

export async function routePremiumApplication(
  formData: PardotFormData,
): Promise<PardotFormData> {
  if (enableLdCalendaringError) {
    return Promise.reject(new Error(`Error loading ${LD_SCRIPT_SRC}`));
  }

  window.parent.postMessage(
    {
      message: "LD_PARDOT_SUBMIT",
      data: {
        formData,
        hiddenFieldNames: new Set(),
      },
    },
    "*",
  );

  return new Promise<PardotFormData>((resolve, reject) => {
    const onMessageHandler = (event: MessageEvent) => {
      if (!isLDMessageEvent(event)) return;

      switch (event.data.message) {
        case "LD_ROUTING_DONE":
          {
            const calendarUrl = localStorage.getItem(LD_CALENDAR_URL_KEY);
            const formInput = event.data.data.formInput;
            window.parent.postMessage(
              {
                message: "LD_FORM_DONE",
                data: "PARDOT",
              },
              "*",
            );
            if (calendarUrl) {
              const data = {
                ...formInput,
                "LD BookIt Calendar Link": calendarUrl,
              };
              resolve(data);
            } else {
              reject(
                new Error(
                  `Failed to retrieve ${LD_CALENDAR_URL_KEY} from localStorage`,
                ),
              );
            }
          }
          break;
        case "LD_ROUTING_ERROR":
        case "LD_ROUTING_TOKEN_ERROR":
        case "LD_ROUTING_VALIDATION_ERROR":
          reject(new Error(event.data.message));
          break;
        default:
          // This case shouldn't happen, but if it does we don't want to
          // remove the event listener prematurely
          return;
      }
      window.removeEventListener("message", onMessageHandler);
    };
    window.addEventListener("message", onMessageHandler, false);
  });
}

type LDMessageEvent =
  | MessageEvent<{
      message: "LD_ROUTING_DONE";
      data: { formInput: PardotFormData };
    }>
  | MessageEvent<{ message: "LD_ROUTING_ERROR" }>
  | MessageEvent<{ message: "LD_ROUTING_TOKEN_ERROR" }>
  | MessageEvent<{ message: "LD_ROUTING_VALIDATION_ERROR" }>;

function isLDMessageEvent(event: MessageEvent): event is LDMessageEvent {
  // TypeScript incantation to make eslint happy
  function hasData(event: MessageEvent): event is MessageEvent<unknown> {
    return "data" in event;
  }
  function hasMessage(data: unknown): data is { message: unknown } {
    if (data === null || typeof data !== "object") return false;
    return "message" in data;
  }
  function messageIsString(data: {
    message: unknown;
  }): data is { message: string } {
    return typeof data.message === "string";
  }
  return (
    hasData(event) &&
    hasMessage(event.data) &&
    messageIsString(event.data) &&
    event.data.message.startsWith("LD_")
  );
}
